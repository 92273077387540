import React from "react"
import Row from "react-bootstrap/Row"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Col from "react-bootstrap/Col"

const Breadcrumbs = ({ crumbs }) => {
  const items = []
  if (crumbs) {
    crumbs.forEach((crumb, key) => {
      items.push(
        <Breadcrumb.Item href={crumb.slug} key={key}>
          {crumb.title}
        </Breadcrumb.Item>
      )
    })
  }

  return (
    <Row id="gt-breadcrumbs-title" className="d-sm-none d-md-block">
      <Col className="breadcrumb-links">
        <Breadcrumb as="nav" aria-label="breadcrumb">
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          {items}
        </Breadcrumb>
      </Col>
    </Row>
  )
}

export default Breadcrumbs
