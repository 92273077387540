import React from "react"
import { Link } from "gatsby"

import Card from "react-bootstrap/Card"
import CardDeck from "react-bootstrap/CardDeck"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import AnniversaryCollage from "../components/85th-anniversary-collage"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Breadcrumbs from "../components/breadcrumb"

const ArchivesPage = () => (
  <Layout>
    <SEO title="Historical Archives and Media Library" />
    <Row>
      <Col id="page-title" className="col col-12">
        <h1 className="title">Historical Archives and Media Library</h1>
      </Col>
    </Row>
    <Breadcrumbs />
    <Row id="content">
      <Col>
        <Row className="justify-content-center">
          <AnniversaryCollage />
        </Row>
        <Row>
          <p className="intro-text">
            Early in its history, the Engineering Experiment Station/Georgia
            Tech Research Institute was home to nearly all of the research
            conducted at Georgia Tech. Much of that remarkable work has been
            documented in publications or captured on film and tape. This
            historical archive provides access to a vast library of information,
            much of which is exclusive to this site and not available in any
            other place.
          </p>
        </Row>
        <Row>
          <CardDeck className="archive-deck">
            <Card>
              <Card.Header>Publications</Card.Header>
              <Card.Body>
                <Card.Text>
                  You will be amazed by this extensive collection of historical
                  news releases, newsletters and research magazines – dating
                  back to the 1950s.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link className="btn btn-gtgold" to="/archives/publications/">
                  View our publications
                </Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Header>Videos</Card.Header>
              <Card.Body>
                <Card.Text>
                  A one-stop-shop to access, view and download all of the video
                  clips embedded throughout GTRI’s Historical Archive.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link className="btn btn-gtgold" to="/archives/videos/">
                  View our videos
                </Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Header>Images</Card.Header>
              <Card.Body>
                <Card.Text>
                  Browse all of the historical photos and graphics featured
                  throughout GTRI’s Historical Archive. Each image is available
                  for download in a variety of sizes.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link className="btn btn-gtgold" to="/archives/images/">
                  View our images
                </Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Header>All Media</Card.Header>
              <Card.Body>
                <Card.Text>
                  Images, videos, interviews, news releases, research magazines,
                  employee newsletters and more - dating back to the 1950s.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link className="btn btn-gtgold" to="/mediasearch/">
                  View all media
                </Link>
              </Card.Footer>
            </Card>
          </CardDeck>
        </Row>
      </Col>
    </Row>
  </Layout>
)

export default ArchivesPage
